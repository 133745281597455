"use client";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useAds } from "@/components/AdsProvider"; // AdsProvider'dan useAds hook'unu import edin

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #ccc",
    background: "#fff",
    overflow: "auto",
    borderRadius: "10px",
    outline: "none",
    padding: "10px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    width: "100%", // Mobil uyumluluk için genişlik ayarlaması
    maxWidth: "700px", // Maksimum genişlik sınırlandırması
    zIndex: 1002, // Modal içeriği için z-index değeri
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)", // Overlay arkaplanı için yarı saydamlık
    zIndex: 1001, // Overlay için z-index değeri
  },
};

export default function PopupComponent() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { showAds } = useAds(); // showAds'i kullanarak abonelik durumunu kontrol edin

  useEffect(() => {
    Modal.setAppElement("body");
    if (!showAds) {
      // Eğer showAds false ise, yani kullanıcı abone ise, modal gösterilmez
      return;
    }

    setTimeout(() => {
      openModal();
      setTimeout(() => {
        closeModal();
      }, 40000);
    }, 20000);
  }, [showAds]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  if (!showAds) return null; // Abone kullanıcılar için null döndürerek hiçbir şey göstermez

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Teknik Analiz Bilgileri"
      >
        <a
          href="/teknik-analiz-borsa-oneri-tablolari"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <img
            src="/assets/teknikanalizresim.png"
            alt="Teknik Analiz Tablosu"
            style={{
              width: "100%",
              height: "auto",
              border: "5px solid black", // 5 piksel kalınlığında kırmızı bir çerçeve
              borderRadius: "10px", // Hafif yuvarlak köşeler
            }}
          />
          <h2 style={{ color: "#333", fontSize: "20px", margin: "10px" }}>
            Günlük Sinyalleri Görmek için Tıklayınız ve Abone Olunuz
          </h2>
          <p style={{ color: "#666", fontSize: "16px", lineHeight: "1.6" }}>
            1- <strong>Teknik analizin</strong>, Borsa İstanbul'da{" "}
            <strong>bugün</strong> hangi hisselere <strong>'Alış'</strong>{" "}
            sinyali, hangi hisselere <strong>'Satış'</strong> sinyali verdiğini
            görmek için,
          </p>
          <p style={{ color: "#666", fontSize: "16px", lineHeight: "1.6" }}>
            2- <strong>Yapay Zekaya</strong> göre en çok yükselme ihtimali olan
            hisseleri öğrenmek için <strong>tıklayınız</strong>.
          </p>
          <p style={{ color: "#666", fontSize: "14px", lineHeight: "1.2" }}>
            {" "}
            <strong>
              Not: Abone olmayanlar bu içeriğe erişemez. Çalışmalarımıza destek
              vermek için lütfen abone olunuz.
            </strong>
          </p>
          <p style={{ color: "#666", fontSize: "12px", lineHeight: "1.2" }}>
            Resim temsilidir.
          </p>
        </a>
        <button
          onClick={closeModal}
          style={{
            backgroundColor: "#007BFF",
            color: "#fff",
            border: "none",
            padding: "10px 20px",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "18px",
            marginTop: "20px",
            transition: "background-color 0.3s",
            display: "block" /* veya "inline-block" */,
            margin: "0 auto",
          }}
        >
          Kapat
        </button>
      </Modal>
    </div>
  );
}
