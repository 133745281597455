"use client";
import React, { useEffect, useRef, memo } from "react";

function TickerTapeWidget() {
  const containerRef = useRef();

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.innerHTML = ""; // Öncelikle mevcut içeriği boşalt
      const script = document.createElement("script");
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
      {
        "symbols": [
          {
            "description": "Dolar",
            "proName": "FX:USDTRY"
          },
          {
            "description": "BIST100",
            "proName": "BIST:XU100"
          },
          {
            "description": "KCHOL",
            "proName": "BIST:KCHOL"
          },
          {
            "description": "THYAO",
            "proName": "BIST:THYAO"
          },
          {
            "description": "Euro",
            "proName": "OANDA:EURTRY"
          },
          {
            "description": "Altın-Gram",
            "proName": "FX_IDC:XAUTRYG"
          },
          {
            "proName": "FOREXCOM:SPXUSD",
            "title": "S&P500"
          },
          {
            "proName": "BITSTAMP:BTCUSD",
            "title": "Bitcoin"
          },
          {
            "proName": "BITSTAMP:ETHUSD",
            "title": "Ethereum"
          }             
        ],
        "showSymbolLogo": false,
        "isTransparent": false,
        "displayMode": "regular",
        "colorTheme": "dark",
        "locale": "tr"
      }`;
      containerRef.current.appendChild(script);
    }
  }, []);

  return (
    <div
      className="tradingview-widget-container"
      ref={containerRef}
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        zIndex: 1000, // Öne çıkarmak için z-index kullanıyoruz
      }}
    >
      <div className="tradingview-widget-container__widget"></div>
      <div className="tradingview-widget-copyright">
        <a
          href="https://tr.tradingview.com/"
          rel="noopener nofollow"
          target="_blank"
        ></a>
      </div>
    </div>
  );
}

export default memo(TickerTapeWidget);
