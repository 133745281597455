"use client";
import React, { useState } from "react";

const NotificationBar = () => {
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false); // Bildirimi kapatma
  };

  if (!visible) return null; // Görünürlüğü false olursa, bileşeni render etme

  return (
    <div className="notificationBar">
      <p>
        Şirketlerin temel analiz performanslarını görmek için,
        <a
          href="https://www.uscmarkets.com/temel-analiz-hisse-oneri-tablolari"
          target="_blank"
        >
          {" "}
          USC Markets Temel Analiz Hisse Öneri Tabloları'na tıklayınız.{" "}
        </a>
      </p>
      <button onClick={handleClose} className="closeButton">
        X
      </button>
    </div>
  );
};

export default NotificationBar;
